import React from 'react';
import { MainLayout } from '../components/templates/MainLayout';
import { History } from '../components/organisms/History';
import { Row, Col } from 'antd';

export const HistoryPage: React.FC = () => {
  return (
    <MainLayout>
      <Row justify="center">
        <Col span={23}>
          <History />
        </Col>
      </Row>
    </MainLayout>
  );
};
