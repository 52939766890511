import React from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './AuthContext';

export const AdminRoute: React.FC<RouteProps> = (props) => {
  const { isAdmin } = useAuth();
  const { children, ...routeProps } = props;

  return <Route {...routeProps}>{isAdmin ? children : <Redirect to="/" />}</Route>;
};
