import { Button, Form, message, Space } from 'antd';
import joiPasswordComplexity from 'joi-password-complexity';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useAuth, User } from '../atoms/AuthContext';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useValidationStatus as $useValidationStatus } from '../atoms/FormUtils';
import { ControlledInput } from '../atoms/ControlledInput';
import { Prompt, useHistory } from 'react-router-dom';

export type UserProfileData = Omit<User, 'id' | 'isActive'> & {
  password?: string;
  repeatPassword?: string;
};
export function UserProfile(): ReactElement {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';
  console.log();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<UserProfileData>({
    defaultValues: {
      firstname: '',
      company: '',
      email: '',
      lastname: '',
      roles: [],
      username: '',
      password: '',
      repeatPassword: '',
    },
    resolver: joiResolver(schema(isNew), { allowUnknown: true, abortEarly: false }),
  });

  useEffect(() => {
    if (isNew) {
      return;
    }
    fetch(`/api/users/${id}`, {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${user?.token}`,
      },
    })
      .then((resp) => resp.json())
      .then((data: User) => reset(data));
  }, [id, isNew, reset, user]);
  const history = useHistory();

  const saveProfile = useCallback(
    (data: UserProfileData) => {
      const msg = isNew ? 'User created successfully' : 'Changes saved';
      const method = isNew ? 'POST' : 'PATCH';
      const endpoint = isNew ? '/api/users' : `/api/users/${id}`;

      return fetch(endpoint, {
        method,
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${user?.token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            reset(data);
            message.success(msg);
            return response.json();
          } else {
            throw new Error('Error while saving changes');
          }
        })
        .then(({ id }: { id: string }) => {
          if (isNew) {
            history.push(`/user/${id}`);
          }
        })
        .catch(() => {
          return message.error('Error while saving changes');
        });
    },
    [isNew, id, user, history, reset]
  );

  const validationStatus = useCallback((name) => $useValidationStatus(name, errors), [errors]);
  const autoComplete = isNew ? 'new-password' : undefined;
  const usernameValidationStatus = isNew ? validationStatus('username') : {};
  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Prompt when={isDirty} message="Are you sure you want to leave? Any unsaved changes will be lost." />
      <Form
        onSubmitCapture={handleSubmit(saveProfile)}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
      >
        <ControlledInput
          {...usernameValidationStatus}
          name="username"
          label="Username"
          control={control}
          disabled={!isNew}
        />
        <ControlledInput {...validationStatus('firstname')} name="firstname" label="Firstname" control={control} />
        <ControlledInput {...validationStatus('lastname')} name="lastname" label="Lastname" control={control} />
        <ControlledInput {...validationStatus('email')} name="email" label="Email" control={control} />
        <ControlledInput {...validationStatus('company')} name="company" label="Company" control={control} />
        <ControlledInput
          {...validationStatus('password')}
          name="password"
          label="Password"
          control={control}
          type="password"
          autoComplete={autoComplete}
        />
        <ControlledInput
          {...validationStatus('repeatPassword')}
          name="repeatPassword"
          label="Repeat Password"
          control={control}
          type="password"
          autoComplete={autoComplete}
        />{' '}
        <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}

const passwordValidator = joiPasswordComplexity({
  lowerCase: 1,
  upperCase: 1,
  min: 8,
  max: 1024,
  numeric: 1,
  symbol: 0,
});

export const schema = (isNew: boolean): Joi.ObjectSchema =>
  Joi.object({
    username: Joi.string().min(1),
    firstname: Joi.string().allow(''),
    lastname: Joi.string().allow(''),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .allow(''),
    company: Joi.string().allow(''),
    password: isNew ? passwordValidator : Joi.alternatives().try(Joi.equal(''), passwordValidator),
    repeatPassword: Joi.when('password', {
      is: Joi.valid(''),
      then: Joi.allow(''),
      otherwise: Joi.equal(Joi.ref('password')).required(),
    }),
  });
