import { Col, Row } from 'antd';
import React from 'react';
import { UserProfile } from '../components/organisms/UserProfile';
import { MainLayout } from '../components/templates/MainLayout';

export const UserProfilePage: React.FC = () => {
  return (
    <MainLayout>
      <Row justify="center">
        <Col xl={{ span: 8 }} md={{ span: 12 }} xs={{ span: 20 }}>
          <UserProfile />
        </Col>
      </Row>
    </MainLayout>
  );
};
