import { Button, Col, DatePicker, Descriptions, Input, Row, Space, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import FileSaver from 'file-saver';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InitialConditionsV2, UnitNamesMap, Units } from '../../pages/SelectByPerformanceChartPage';
import { useAuth, User } from '../atoms/AuthContext';

export type HistoryDto = {
  id: string;
  ref: string;
  selection: string;
  initialConditions: Omit<InitialConditionsV2, 'filter'>;
  file: FileDescriptor;
  timestamp: Date;
  user: Omit<User, 'roles'>;
  units: Units
};

export type FileDescriptor = {
  bucket: string;
  file: string;
};

export const History: React.FC = () => {
  const rangeStart = useMemo(() => moment().subtract(7, 'days').startOf('day'), []);
  const rangeEnd = useMemo(() => moment().endOf('day'), []);

  const { user } = useAuth();
  const [history, setHistory] = useState<HistoryDto[]>([]);
  const [filter, setFilter] = useState<moment.Moment[]>([rangeStart, rangeEnd]);
  const [ref, setRef] = useState<string | undefined>();

  const onSearch = useCallback(() => {
    const query = new URLSearchParams();
    query.set('from', String(filter[0].startOf('day').toDate().getTime()));
    query.set('to', String(filter[1].endOf('day').toDate().getTime()));
    query.set('ref', ref ?? '');

    fetch('/api/history?' + query.toString(), {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${user?.token}`,
      },
    })
      .then((data) => data.json())
      .then(setHistory);
  }, [filter, ref, user]);

  useEffect(() => {
    onSearch();
  }, [filter])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row>
        <Col style={{ marginRight: '6px' }}>
          <DatePicker.RangePicker
            defaultValue={[rangeStart, rangeEnd]}
            onChange={(values) => {
              if (values && values[0] && values[1]) {
                setFilter([values[0], values[1]]);
              }
            }}
            allowClear={false}
          />
        </Col>
        <Col>
          <Input.Search
            placeholder="Search Reference Code"
            onChange={(event) => {
              setRef(event.target.value);
            }}
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <Table dataSource={history} rowKey="id" pagination={false}>
        <Column title="Reference Code" dataIndex="ref" />
        <Column
          title="User"
          dataIndex={['user', 'username']}
          filters={[...new Set(history.map((history) => history.user.username))].map((f) => ({ value: f, text: f }))}
          onFilter={(value, record: HistoryDto) => record.user.username == value}
          sorter
        />
        <Column
          title="Selection"
          dataIndex="selection"
          width={125}
          filters={[
            { text: 'REX150', value: 'REX150' },
            { text: 'REX190', value: 'REX190' },
            { text: 'REX300', value: 'REX300' },
            { text: 'REX435', value: 'REX435' },
          ]}
          onFilter={(value, record: HistoryDto) => record.selection == value}
        />
        <Column title="Date" dataIndex="timestamp" render={(date) => new Date(date).toLocaleString()} width={200} />
        <Column
          title="Conditions"
          dataIndex="initialConditions"
          render={(conditions: Omit<InitialConditionsV2, 'filter'>) => {
            const units = conditions.units ?? Units.IMPERIAL;
            return (
              <Descriptions
                labelStyle={{
                  fontWeight: 'bold',
                }}
              >
                <Descriptions.Item label="Inlet temperature">{conditions.inletTemperature} {UnitNamesMap[units].temperature}</Descriptions.Item>
                <Descriptions.Item label="Outlet temperature">{conditions.outletTemperature} {UnitNamesMap[units].temperature}</Descriptions.Item>
                <Descriptions.Item label="Supply temperature">{conditions.supplyTemperature} {UnitNamesMap[units].temperature}</Descriptions.Item>
                <Descriptions.Item label="Return temperature">{conditions.returnTemperature} {UnitNamesMap[units].temperature}</Descriptions.Item>
                <Descriptions.Item label="Required flow">{conditions.requiredFlow} {UnitNamesMap[units].volumePerHour}</Descriptions.Item>
              </Descriptions>
            );
          }}
        />
        <Column
          title="Selection sheet"
          dataIndex="file"
          render={(file: FileDescriptor) => (
            <Button
              title="Download .rtf"
              type="primary"
              onClick={() => FileSaver.saveAs(`/api/history/sheet/${file.bucket}/${file.file}?token=${user?.token}`)}
            >
              Download .rtf
            </Button>
          )}
        />
      </Table>
    </Space>
  );
};
