import { Layout } from "antd";
import { BasicProps, Content } from "antd/lib/layout/layout";
import React from "react";
import styled from "styled-components";

const StyledLayout = styled(Layout)`
  height: calc(100vh - 64px);
`;

type StyledContentProps = React.FC<BasicProps> & {
  isCollapsed?: boolean;
};

const StyledContent = styled<StyledContentProps>(Content)`
  padding-top: 3em;

  @media (max-width: 575px) {
    /* padding: 0; */
  }

  background: #fff;

  /* & > .ant-row > .ant-col:not(:first-child) { */
    /* padding-top: ${(props: StyledContentProps) => (props.isCollapsed ? '3em' : '0')}; */
  /* } */
`;

export const MainLayout: React.FC = (props) => {
  return (
    <StyledLayout>
      <StyledContent>
        {props.children}
      </StyledContent>
    </StyledLayout>
  );
}