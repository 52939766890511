import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import { Button, Space, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import FileSaver from 'file-saver';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../components/atoms/AuthContext';
import { MainLayoutRow } from '../components/atoms/MainLayoutRow';
import { SelectByPerformanceChart } from '../components/organisms/SelectByPerformanceChart';
import { MainLayout } from '../components/templates/MainLayout';

export enum Units {
  IMPERIAL,
  METRIC,
}

export type UnitNames = {
  temperature: string;
  volumePerHour: string;
  volumePerMinute: string;
  pressure: string;
  power: string;
};

export const UnitNamesMap: Record<Units, UnitNames> = {
  [Units.IMPERIAL]: {
    temperature: '\u00B0F',
    volumePerHour: 'GPH',
    volumePerMinute: 'GPM',
    pressure: 'PSI',
    power: 'BTU/hr x 1000',
  },
  [Units.METRIC]: {
    temperature: '\u00B0C',
    volumePerHour: 'LPH',
    volumePerMinute: 'LPM',
    pressure: 'kPa',
    power: 'KW',
  },
};

export type InitialConditionsV2 = {
  inletTemperature: number;
  outletTemperature: number;
  supplyTemperature: number;
  returnTemperature: number;
  requiredFlow: number;
  filter: REX;
  units: Units;
};

enum REX {
  ALL = -1,
  REX435,
  REX300,
  REX190,
  REX150,
}

export const DefaultValues: Record<Units, InitialConditionsV2> = {
  [Units.IMPERIAL]: {
    requiredFlow: 1000,
    inletTemperature: 40,
    outletTemperature: 130,
    supplyTemperature: 180,
    returnTemperature: 90,
    filter: -1,
    units: Units.IMPERIAL,
  },
  [Units.METRIC]: {
    requiredFlow: 3785,
    inletTemperature: 5,
    outletTemperature: 55,
    supplyTemperature: 80,
    returnTemperature: 30,
    filter: -1,
    units: Units.METRIC,
  },
};

export type SelectionSheetValues = {
  supplyTemperature: number;
  returnTemperature: number;
  inletTemperature: number;
  outletTemperature: number;
  netBoilerOutput: number;
  pumpFlowRate: number;
  requiredFlow: number;
  n: number;
  domesticHotWaterPressureDrop: number;
  boilerSidePressureDrop: number;
  rex: string;
};

type SheetType = 'rtf' | 'pdf';

type SheetRequest = {
  values: SelectionSheetValues;
  type: SheetType;
};

export const SelectByPerformanceChartPage: React.FC = () => {
  const [selection, setSelection] = useState();
  const [conditions, setConditions] = useState(undefined as InitialConditionsV2 | undefined);
  const [units, setUnits] = useState(Units.IMPERIAL);

  const submit = (data: InitialConditionsV2) => {
    setConditions(data);
    fetch('/api/v2/exchanger', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((selection) => {
        setSelection(selection);
        setUnits(data.units);
      });
  };
  return (
    <MainLayout>
      <MainLayoutRow>
        <SelectByPerformanceChart submit={submit} />
      </MainLayoutRow>
      <MainLayoutRow>
        <SelectionResultTable selection={selection} conditions={conditions} units={units} />
      </MainLayoutRow>
    </MainLayout>
  );
};

export type SelectionV2 = {
  n: number;
  rex: string;
  netBoilerOutput: number;
  pumpFlowRate: number;
  requiredFlow: number;
  boilerSidePressureDrop: number;
  domesticHotWaterPressureDrop: number;
};

const Selection = ({
  selection,
  conditions,
  className,
  units = Units.IMPERIAL,
}: {
  selection?: SelectionV2[];
  conditions?: InitialConditionsV2;
  units?: Units;
  className?: string;
}): React.ReactElement => {
  const { user } = useAuth();
  const dataSource = selection?.map((s) => {
    return {
      key: String(s.n) + '_' + s.rex,
      selection: String(s.n) + ' ' + s.rex,
      ...s,
    };
  });

  const [loading, setLoading] = useState([false, false, false, false]);

  const downloadFile = useCallback(
    (type: SheetType, record: SelectionV2, index: number) => {
      if (conditions == null) {
        return;
      }

      setLoading((loading) => {
        const l = [...loading];
        l[index] = true;

        return l;
      });

      const params = {
        type,
        ...record,
        ...conditions,
        units: units == Units.IMPERIAL ? 'imperial' : 'metric',
        token: user?.token,
      };
      const query = new URLSearchParams(JSON.parse(JSON.stringify(params)));

      // FileSaver.saveAs('/api/selection-sheet?' + query.toString());
      // console.log("saved")
      fetch('/api/selection-sheet?' + query.toString()).then((res) => {
        const filename = new window.URL(res.url).pathname.split('/').reverse()[0];

        setLoading((loading) => {
          const l = [...loading];
          l[index] = false;

          return l;
        });

        res.blob().then((blob) => {
          FileSaver.saveAs(blob, filename);
        });
      });
    },
    [conditions, units, user?.token]
  );

  return (
    <div className={className}>
      <div className="ant-descriptions-header">
        <div className="ant-descriptions-title">Selected Exchanger</div>
      </div>
      <Table dataSource={dataSource} pagination={false} bordered>
        <Column title="Selection" key="selection" dataIndex="selection" />
        <ColumnGroup title="Thru Domestic Hot Water Side">
          <Column
            title={`Flow Rate [${UnitNamesMap[units].volumePerHour}]`}
            dataIndex={['requiredFlow']}
            key="domesticHotWaterSide.flowRatePerHour"
          />
          <Column
            title={`Flow Rate [${UnitNamesMap[units].volumePerMinute}]`}
            dataIndex={['requiredFlow']}
            key="domesticHotWaterSide.flowRatePerMinute"
            render={(value) => <> {(value / 60).toFixed(2)} </>}
          />
          <Column
            title={`Pressure Drop [${UnitNamesMap[units].pressure}]`}
            dataIndex={['domesticHotWaterPressureDrop']}
            key="domesticHotWaterSide.pressureDrop"
            render={(value) => <>{value.toFixed(2)}</>}
          />
        </ColumnGroup>
        <ColumnGroup title="Thru Boiler Water Side">
          <Column
            title={`Net Boiler Output [${UnitNamesMap[units].power}]`}
            dataIndex={['netBoilerOutput']}
            key="netBoilerOutput"
            render={(value) => <>{(value / (units == Units.IMPERIAL ? 1000 : 1)).toFixed(0)}</>}
          />
          <Column
            title={`Pump Flow Rate [${UnitNamesMap[units].volumePerMinute}]`}
            dataIndex={['pumpFlowRate']}
            key="pumpFlowRate"
            render={(value) => <>{value.toFixed(2)}</>}
          />
          <Column
            title={`Pressure Drop [${UnitNamesMap[units].pressure}]`}
            dataIndex={['boilerSidePressureDrop']}
            key="boilerWaterSide.pressureDrop"
            render={(value) => <>{value.toFixed(2)}</>}
          />
        </ColumnGroup>
        <Column
          title="Selection Sheet"
          key="download"
          render={(_, record: SelectionV2, index) => (
            <Space>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => {
                  downloadFile('rtf', record, index);
                }}
                loading={loading[index]}
              >
                Download .rtf
              </Button>
            </Space>
          )}
        />
      </Table>
    </div>
  );
};

const SelectionResultTable = styled(Selection)`
  /* max-width: 600px; */
  margin: 3em auto;
  padding: 0 3em;

  .ant-descriptions-item-label {
    font-weight: 500;
  }

  @media (max-width: 575px) {
    padding: 0 1em;
    margin: 1em auto;

    & .ant-descriptions {
      :not(:first-child) {
        margin-top: 1em;
      }
    }
  }
`;
