import { Row } from 'antd';
import React from 'react';
import { MainLayoutColumn } from './MainLayoutColumn';

export const MainLayoutRow: React.FC = (props) => {
  return (
    <Row justify="center">
      <MainLayoutColumn span={24}>{props.children}</MainLayoutColumn>
    </Row>
  );
};
