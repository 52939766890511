import { Form, Input } from 'antd';
import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

export type ValidationStatus = { validateStatus: 'success' | 'error'; help?: string };

type InputProps<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  control: Control<T>;
  disabled?: boolean;
  type?: string;
  autoComplete?: string;
} & Partial<ValidationStatus>;

export function ControlledInput<T extends FieldValues>({
  control,
  name,
  label,
  disabled,
  validateStatus = 'success',
  help,
  type = 'text',
  autoComplete
}: InputProps<T>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name } }) => {
        // UGLY HACK
        let v;
        if (checkValue(value)) {
          v = value;
        } else {
          v = '';
        }
        return (
          <Form.Item label={label} validateStatus={validateStatus} help={help}>
            <Input autoComplete={autoComplete} disabled={disabled} onChange={onChange} onBlur={onBlur} value={v} name={name} type={type}></Input>
          </Form.Item>
        );
      }}
    />
  );
}

const checkValue = (v: unknown): v is React.InputHTMLAttributes<unknown>['value'] => {
  return true;
};
