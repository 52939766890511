import React from 'react';
import { Button, Col, Form, InputNumber as $InputNumber, Row, SliderSingleProps } from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel';
import styled from 'styled-components';

export const FormItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
  }

  & .ant-btn {
    width: 32px;
    height: 32px;
    padding: 0;
    flex-shrink: 0;
  }

  .ant-input-number {
    height: 32px;
    width: 100%;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-color: #d9d9d9;
    border-top-color: #d9d9d9;
    box-shadow: none;

    :hover,
    :focus {
      border-bottom-color: #d9d9d9;
      border-top-color: #d9d9d9;
    }
  }

  .decr-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .incr-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export const NumberInput = <T,>(
  props: UseControllerProps<T> & SliderSingleProps & FormItemLabelProps & { setValue: (value: number) => void }
): React.ReactElement => {
  const { setValue } = props;
  const {
    field: { value, onChange, name, onBlur, ref },
  } = useController(props);

  const increment = () => {
    setValue(Number(value) + 1);
  };
  const decrement = () => {
    setValue(Number(value) - 1);
  };

  return (
    <Row>
      <Col span={24}>
        <FormItem label={props.label}>
          <Button onClick={decrement} className="decr-btn">
            -
          </Button>
          <$InputNumber
            name={name}
            onBlur={onBlur}
            value={Number(value)}
            ref={ref}
            onChange={onChange}
            min={props.min}
            max={props.max}
          />
          <Button onClick={increment} className="incr-btn">
            +
          </Button>
        </FormItem>
      </Col>
    </Row>
  );
};
