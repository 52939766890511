import React, { useEffect } from 'react';
import { Form, Card as $Card, Select, Row, Col, Radio, RadioChangeEvent } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { DefaultValues, InitialConditionsV2, UnitNamesMap, Units } from '../../pages/SelectByPerformanceChartPage';
import { SubmitButton } from '../atoms/SubmitButton';
import { NumberInput } from '../molecules/NumberInput';

const Card = styled($Card)`
  max-width: 600px;
  margin: 3em auto;
  padding: 0 3em;

  @media (max-width: 575px) {
    padding: 0 1em;
    margin: 1em auto;
  }
`;

export const SelectByPerformanceChart: React.FC<{
  submit: (data: InitialConditionsV2) => void;
}> = (props) => {
  const { submit } = props;
  const { handleSubmit, control, setValue, watch, reset } = useForm({ defaultValues: DefaultValues[Units.IMPERIAL] });
  const { sm: breakpoint } = useBreakpoint();
  const setFormFieldValue = useCallback(
    (name: keyof InitialConditionsV2) => (value: number) => setValue(name, value),
    [setValue]
  );

  const units = watch('units');

  useEffect(() => {
    reset(DefaultValues[units]);
  }, [reset, units]);

  const formItemLayout = breakpoint
    ? {
        labelCol: { span: 16 },
        wrapperCol: { span: 24 },
      }
    : null;
  return (
    <Card>
      <Form onSubmitCapture={handleSubmit(submit)} layout={breakpoint ? 'horizontal' : 'vertical'} {...formItemLayout}>
        <Row>
          <Col span={24}>
            <Form.Item label="Units">
              <Controller
                control={control}
                name="units"
                render={({ field: { value, onChange } }) => {
                  return (
                    <Radio.Group
                      style={{ width: '100%' }}
                      value={value}
                      onChange={onChange}
                      options={[
                        { label: 'Imperial', value: Units.IMPERIAL },
                        { label: 'Metric', value: Units.METRIC },
                      ]}
                      optionType="button"
                    />
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Heat Exchanger">
              <Controller
                control={control}
                name="filter"
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <Select style={{ width: '100%' }} value={value} onChange={onChange} onBlur={onBlur}>
                      <Select.Option value={-1}>All</Select.Option>
                      <Select.Option value={0}>REX 435</Select.Option>
                      <Select.Option value={1}>REX 300</Select.Option>
                      <Select.Option value={2}>REX 190</Select.Option>
                      <Select.Option value={3}>REX 150</Select.Option>
                    </Select>
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <NumberInput
          label={`Required Continuous Flow [${UnitNamesMap[units].volumePerHour}]`}
          name="requiredFlow"
          control={control}
          setValue={setFormFieldValue('requiredFlow')}
        />
        <NumberInput
          label={`Inlet Temperature [${UnitNamesMap[units].temperature}]`}
          name="inletTemperature"
          control={control}
          setValue={setFormFieldValue('inletTemperature')}
        />
        <NumberInput
          label={`Outlet Temperature [${UnitNamesMap[units].temperature}]`}
          name="outletTemperature"
          control={control}
          setValue={setFormFieldValue('outletTemperature')}
        />
        <NumberInput
          label={`Supply Temperature [${UnitNamesMap[units].temperature}]`}
          name="supplyTemperature"
          control={control}
          setValue={setFormFieldValue('supplyTemperature')}
        />
        <NumberInput
          label={`Return Temperature [${UnitNamesMap[units].temperature}]`}
          name="returnTemperature"
          control={control}
          setValue={setFormFieldValue('returnTemperature')}
        />
        <Form.Item>
          <SubmitButton type="primary" htmlType="submit">
            Select Exchanger
          </SubmitButton>
        </Form.Item>
      </Form>
    </Card>
  );
};
