import { Menu } from 'antd';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import React from 'react';
import { Link, Redirect, Route } from 'react-router-dom';
import { AdminRoute } from './components/atoms/AdminRoute';
import { useAuth } from './components/atoms/AuthContext';
import { ProtectedRoute } from './components/atoms/ProtectedRoute';
import { LoginPage } from './pages/LoginPage';
import { UserProfilePage } from './pages/UserProfilePage';
import { UsersListPage } from './pages/UsersListPage';
import styled from 'styled-components';
import { SelectByPerformanceChartPage } from './pages/SelectByPerformanceChartPage';
import { HistoryPage } from './pages/HistoryPage';

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderItem = styled(Menu.Item)`
  .ant-menu-horizontal > &.ant-menu-item.ant-menu-item-selected {
    background-color: transparent;
    user-select: none;
  }
`;

export const App = (): React.ReactElement => {
  const { user, isAdmin, signOut } = useAuth();
  
  return (
    <Layout>
      <Header>
        {user != null && (
          <HeaderContent>
            <Menu theme="dark" mode="horizontal">
              <HeaderItem>
                <Link to="/perf-chart">Select exchanger by Performance Chart</Link>
              </HeaderItem>
              {isAdmin && (
                <>
                  <HeaderItem>
                    <Link to="/user">Users</Link>
                  </HeaderItem>
                  <HeaderItem>
                    <Link to="/history">History</Link>
                  </HeaderItem>
                </>
              )}
            </Menu>
            <Menu theme="dark" mode="horizontal">
              <HeaderItem onClick={signOut}>Logout</HeaderItem>
            </Menu>
          </HeaderContent>
        )}
      </Header>
      <Content>
        <AdminRoute exact path="/user">
          <UsersListPage />
        </AdminRoute>
        <AdminRoute exact path="/user/:id">
          <UserProfilePage />
        </AdminRoute>
        <AdminRoute exact path="/history">
          <HistoryPage />
        </AdminRoute>
        <Route exact path="/">
          {user != null ? <Redirect to="/perf-chart" /> : <LoginPage />}
        </Route>
        <ProtectedRoute path="/perf-chart">
          <SelectByPerformanceChartPage />
        </ProtectedRoute>
      </Content>
    </Layout>
  );
};
