import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';

export type User = {
  id: string;
  isActive: boolean;
  username: string;
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  roles: string[];
};

export type LoggedUser = {
  id: string;
  roles: string[];
  token: string;
};

export interface AuthContext {
  user: LoggedUser | undefined;
  isAdmin: boolean;
  signIn: (usename: string, password: string) => Promise<void>;
  signOut: () => void;
}

const AuthContext = createContext<AuthContext>({
  user: undefined,
  signIn: () => Promise.resolve(),
  signOut: () => {
    return;
  },
  isAdmin: false,
});

export const useAuthProvider = (): React.FC => {
  const [user, setUser] = useState<LoggedUser | undefined>();

  const refreshToken = async () => {
    const res = await fetch('/api/token/refresh');
    if (res.status == 200) {
      const user: LoggedUser = await res.json();
      setUser(user);
    }
  };

  useEffect(() => {
    refreshToken();
  }, []);

  const signIn = async (username: string, password: string): Promise<void> => {
    const res = await fetch('/api/signin', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: {
        'content-type': 'application/json',
      },
    });
    if (res.status == 200) {
      const user: LoggedUser = await res.json();

      setUser(user);
      return Promise.resolve();
    }

    return Promise.reject();
  };

  const signOut = () => {
    setUser(undefined);
    fetch('/api/signout');
  };

  return ({ children }) => {
    return (
      <AuthContext.Provider
        value={{
          user,
          signIn,
          signOut,
          isAdmin: user != null && user.roles != null && user.roles.includes('admin'),
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
};

export const useAuth = (): AuthContext => {
  return useContext(AuthContext);
};
