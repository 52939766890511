import React from 'react';
import { Button, Form, Input, Row, Space } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from '../components/atoms/AuthContext';
import { MainLayout } from '../components/templates/MainLayout';
import { MainLayoutColumn } from '../components/atoms/MainLayoutColumn';
import { Link } from 'react-router-dom';

const layout = {
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const width100 = {
  width: '100%',
};

export const LoginPage: React.FC = () => {
  const { signIn } = useAuth();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const submit = ({ username, password }: { username: string; password: string }) => {
    signIn(username, password);
  };

  return (
    <MainLayout>
      <Row justify="center" align="middle">
        <MainLayoutColumn lg={6} sm={12} xs={22}>
          <Space direction="vertical" style={width100} size="small">
            <Form {...layout} onSubmitCapture={handleSubmit(submit)}>
              <Form.Item name="username">
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => {
                    return <Input placeholder="Username" {...field} />;
                  }}
                />
              </Form.Item>

              <Form.Item name="password">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => {
                    return <Input.Password placeholder="Password" {...field} />;
                  }}
                />
              </Form.Item>

              <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit" style={width100}>
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </MainLayoutColumn>
      </Row>
    </MainLayout>
  );
};
