import React, { useCallback, useEffect, useReducer } from 'react';
import { Button, Input, Row, Space, Table, Tag } from 'antd';
import Column from 'antd/lib/table/Column';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth, User } from '../atoms/AuthContext';

const { Search } = Input;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function filterUsers(filter: string | undefined, users: User[]) {
  const columns: (keyof User)[] = ['firstname', 'lastname', 'company'];
  if (!filter) {
    return users;
  }
  return users.filter((user) => {
    return columns.some(
      (column) => typeof user[column] == 'string' && (user[column] as string | undefined)?.startsWith(filter)
    );
  });
}

type Action = {
  type: string;
  payload: {
    users?: User[];
    filter?: string;
  };
};

type UsersListState = {
  users: User[];
  filteredUsers: User[];
  filter: string;
};

export const UsersList = (): JSX.Element => {
  const { user } = useAuth();
  const queryFilter = useQuery().get('filter') ?? '';
  const [state, dispatch] = useReducer(
    (state: UsersListState, { type, payload: { users = [], filter = '' } }: Action) => {
      switch (type) {
        case 'users':
          return {
            filter: state.filter,
            users,
            filteredUsers: filterUsers(state.filter, users),
          };
        case 'filter':
          return {
            filter,
            users: state.users,
            filteredUsers: filterUsers(filter, state.users),
          };
      }
      return state;
    },
    {
      users: [] as User[],
      filteredUsers: [] as User[],
      filter: queryFilter,
    }
  );
  const history = useHistory();

  const fetchUsers = useCallback(() => {
    fetch('/api/users', {
      headers: {
        authorization: `Bearer ${user?.token}`,
      },
    })
      .then((resp) => resp.json())
      .then((users) => dispatch({ type: 'users', payload: { users } }));
  }, [user]);

  const toggleIsActive = useCallback(
    (isActive: boolean, id: string) => {
      fetch(`/api/users/${id}`, {
        method: 'PATCH',
        body: JSON.stringify({ isActive }),
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${user?.token}`,
        },
      }).then(fetchUsers);
    },
    [user, fetchUsers]
  );

  const activate = (id: string) => toggleIsActive(true, id);
  const deactivate = (id: string) => toggleIsActive(false, id);

  useEffect(fetchUsers, [fetchUsers]);

  const onFilter = (filter: string) => {
    dispatch({
      type: 'filter',
      payload: {
        filter,
      },
    });
    return history.push({
      search: `?filter=${filter}`,
    });
  };

  const useActions = (_text: string, record: User) => {
    if (record.isActive) {
      return (
        <Space size="middle">
          <a onClick={() => deactivate(record.id)}>Deactivate</a>
          <a
            onClick={() =>
              history.push({
                pathname: `${history.location.pathname}/${record.id}`,
              })
            }
          >
            Edit profile
          </a>
        </Space>
      );
    }
    return (
      <Space size="middle">
        <a onClick={() => activate(record.id)}>Activate</a>
        <a
          onClick={() =>
            history.push({
              pathname: `${history.location.pathname}/${record.id}`,
            })
          }
        >
          Edit profile
        </a>
      </Space>
    );
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <Search style={{ width: '200px' }} onSearch={onFilter} defaultValue={queryFilter}></Search>
        <Link to="/user/new">
          <Button type="primary">Add User</Button>
        </Link>
      </Row>
      <Table dataSource={state.filteredUsers}>
        <Column title="Username" dataIndex="username" key="username" />
        <Column title="First Name" dataIndex="firstname" key="firstname" />
        <Column title="Last Name" dataIndex="lastname" key="lastname" />
        <Column title="Company" dataIndex="company" key="company" />
        <Column title="Email" dataIndex="email" key="email" />
        <Column
          title="Roles"
          dataIndex="roles"
          key="roles"
          render={(roles) => (
            <>
              {roles.map((role: string) => (
                <Tag color="blue" key={role}>
                  {role}
                </Tag>
              ))}
            </>
          )}
        />
        <Column
          title="Active"
          dataIndex="isActive"
          key="isActive"
          render={(isActive) => <Tag color={isActive ? 'green' : 'red'}> {isActive ? 'Active' : 'Inactive'}</Tag>}
          filters={[
            {
              text: 'Active',
              value: true,
            },
            {
              text: 'Inactive',
              value: false,
            },
          ]}
          onFilter={(value, record: User) => value === record.isActive}
        />
        <Column title="Actions" key="actions" render={useActions} />
      </Table>
    </Space>
  );
};
