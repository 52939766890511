import { FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import { ValidationStatus } from './ControlledInput';

export function errorsToStatus<T extends FieldValues = FieldValues>(errors: FieldErrors<T>): Record<keyof T, string | undefined> {
  const validationStatus = Object.entries(errors).reduce((status, entry: [keyof T, FieldError]) => {
    const [key, value] = entry;
    status[key] = value.message;
    return status;
  }, {} as Record<keyof T, string | undefined>);
  return validationStatus;
}

export const useValidationStatus = <T extends FieldValues = FieldValues>(name: keyof T, errors: FieldErrors<T>): ValidationStatus => {
  const validationStatus = errorsToStatus(errors);
  if (validationStatus[name] == null) {
    return {
      validateStatus: 'success',
    };
  }

  return { validateStatus: 'error', help: validationStatus[name] };
};
