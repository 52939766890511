import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import { useAuthProvider } from './components/atoms/AuthContext';

const Application = () => {
  const AuthProvider = useAuthProvider();

  return (
    <AuthProvider>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  );
};

ReactDOM.render(<Application />, document.getElementById('root'));
