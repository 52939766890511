import { Col, Row } from 'antd';
import React from 'react';
import { UsersList } from '../components/organisms/UsersList';
import { MainLayout } from '../components/templates/MainLayout';

export const UsersListPage: React.FC = () => {
  return (
    <MainLayout>
      <Row justify="center">
        <Col span={23}>
          <UsersList />
        </Col>
      </Row>
    </MainLayout>
  );
};
